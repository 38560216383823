<template>
    <div>
      <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
      <div v-else>
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/chartConfig' }">数据模型</el-breadcrumb-item>
            <el-breadcrumb-item>编辑模型</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="form-box">
          <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
            <div class="form-title">基本信息</div>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="模型名称" prop="item">
                  <el-input v-model="form.item" maxlength="30" placeholder="请输入模型名称" show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
  
            <div class="bottom-btn">
              <el-button type="primary" size="small" @click="$router.push('/chartConfig')">取消</el-button>
              <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        loading: false,
        form: {},
        rules: {
            item: [{ required: true, message: "必填项", trigger: "blur" }],
        }
      };
    },
    methods: {
      // 提交数据
      onSubmit() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            let form = {
              item: this.form.item,
              itemId: this.form.itemId,
            }
            this.loading = true;
            this.$ajax.post("dataModelItemEdit", form).then((res) => {
              this.loading = false;
              this.$message.success("成功");
              this.$router.push("/chartConfig");
            }).catch(err=>{
              this.loading = false;
            })
          }
        });
      },
    },
    mounted() {
        if (this.$route.params == null) {
            this.$router.push('/chartConfig')
        } else {
            this.form = this.$route.params
        }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .mains {
    padding: 400px 0;
    height: 100%;
  }
  /deep/.el-loading-spinner .el-icon-loading {
    font-size: 50px;
  }
  .breadcrumb {
    padding: 15px 10px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  .el-input {
    width: 400px;
  }
  </style>